import React from 'react';

import Layout from '../components/v2/Layout';
import Hero from '../components/v2/Hero';
import PageSection from '../components/v2/PageSection.jsx';
import ContentBlock from '../components/v2/ContentBlock';
import Image from '../components/v2/Image';
import ImageGrid from '../components/v2/ImageGrid';
import ImageBlock from '../components/v2/ImageBlock';

import PrevNextNavFooter from '../components/v2/ui-blocks/PrevNextNavFooter/PrevNextNavFooter';

import hero_img from '../images/4. Tagline/Asset 4@300x.png';

import tagline_LGP_logo from '../images/4. Tagline/tagline-LGP-logo-2560x1280.jpg';

import Toyota_Tagline_Incorrect_Usage_1_svg from '../images/4. Tagline/Toyota_Tagline_Incorrect_Usage_1.svg';
import Toyota_Tagline_Incorrect_Usage_2_svg from '../images/4. Tagline/Toyota_Tagline_Incorrect_Usage_2.svg';
import Toyota_Tagline_Incorrect_Usage_3_svg from '../images/4. Tagline/Toyota_Tagline_Incorrect_Usage_3.svg';
import Toyota_Tagline_Incorrect_Usage_4_svg from '../images/4. Tagline/Toyota_Tagline_Incorrect_Usage_4.svg';
import Toyota_Tagline_Incorrect_Usage_5_svg from '../images/4. Tagline/Toyota_Tagline_Incorrect_Usage_5.svg';
import Toyota_Tagline_Incorrect_Usage_6_svg from '../images/4. Tagline/Toyota_Tagline_Incorrect_Usage_6.svg';

import { pageLinks } from '../data/pageLinks';

const introText =
  "<em> <strong>Let’s Go Places</strong></em> is the expression that captures the progressiveness and optimism that Toyota stands for today. It speaks to our ambition to move forward together and positions the brand in a way that's relevant and inclusive for all New Zealanders. Whatever their adventures may be and wherever they may take them.";

const Tagline = ({ location }) => {
  return (
    <Layout appLocation={location}>
      <Hero image={hero_img} text={introText} navindex="0" textPos={'center'} />
      <PageSection sectionId="used-as-headline">
        <ContentBlock>
          <div className="text">
            <p>
              Use our tagline <em>Let’s Go Places</em> with intention and
              integrity. Follow the guidelines below for correct usage whether
              applied as a headline, a logo or as the concluding statement in
              body text.
            </p>
          </div>
        </ContentBlock>
        <ContentBlock>
          <h2>USED AS A HEADLINE</h2>
          <div className="text">
            <p>
              When used as a headline, <em>Let’s Go Places</em> may be used on
              one line or stacked across two or three lines. It should be
              typeset in title case and Semibold Italic and should not include a
              full stop. Do not use in combination with the{' '}
              <em>Let’s Go Places</em> logo. The tagline, however, may be
              repeated when used at the end of body copy.
            </p>
          </div>
        </ContentBlock>
      </PageSection>
      <PageSection sectionId="used-as-logo">
        <ContentBlock noTopSpacing={true}>
          <h2>
            <em>Let’s Go Places</em> logo
          </h2>
          <div className="text">
            <p>
              There are three configurations of the <em>Let’s Go Places</em>{' '}
              logo: horizontal stacked, horizontal and vertical. Use the
              arrangement most appropriate to the format of the communication
              and the design layout. Go to the logo section for more direction.
            </p>
          </div>
        </ContentBlock>
        <ImageBlock>
          <Image src={tagline_LGP_logo} width={'full'} />
        </ImageBlock>
      </PageSection>
      <PageSection sectionId="used-in-body-text" >
        <ContentBlock>
          <h2>Used in Body Text</h2>
          <div className="text">
            <p>
              <em>Let’s Go Places</em> can be typeset within the body copy or at the end. It should follow the same type size and colour as the body copy. But to ensure it stands out, it should be set in title case and Semibold Italic. <em>Let’s Go Places</em> is always italicised.
            </p>
          </div>
        </ContentBlock>
      </PageSection>
      {/* <PageSection sectionId="incorrect-use">
        <ContentBlock>
          <h2>Incorrect Use</h2>
        </ContentBlock>
        <ImageGrid fullWidth={true}>
          <Image
            width="half"
            src={Toyota_Tagline_Incorrect_Usage_1_svg}
            padding={'5rem'}
            caption={"Don't recreate or alter the logo artwork."}
          />
          <Image
            width="half"
            src={Toyota_Tagline_Incorrect_Usage_2_svg}
            caption={"Don't add <em>Let’s Go Places</em> to a vehicle logo."}
            padding={'5rem'}
          />
          <Image
            width="half"
            src={Toyota_Tagline_Incorrect_Usage_3_svg}
            caption={
              "Don't use the logo with body text instead of typesetting <em>Let’s Go Places</em> in Semibold italic."
            }
            padding={'5rem'}
          />
          <Image
            width="half"
            src={Toyota_Tagline_Incorrect_Usage_4_svg}
            caption={"Don't add <em>Let’s Go Places</em> to the brand logo."}
            padding={'5rem'}
          />
          <Image
            width="half"
            src={Toyota_Tagline_Incorrect_Usage_5_svg}
            caption={
              "Don't change the type size or colour of <em>Let’s Go Places</em> when used in body text."
            }
            padding={'5rem'}
          />
          <Image
            width="half"
            src={Toyota_Tagline_Incorrect_Usage_6_svg}
            caption={
              "Don't use <em>Let’s Go Places</em> as a subhead in body text."
            }
            padding={'5rem'}
          />
        </ImageGrid>
      </PageSection> */}

      <PrevNextNavFooter
        previousText="Previous"
        previousHeader="Colours"
        previousUrl={pageLinks.colours}
        nextText="Next"
        nextHeader="Photography"
        nextUrl={pageLinks.photography}
      />
    </Layout>
  );
};

export default Tagline;
